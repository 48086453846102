$breakpoint-tablet: 767px;

.navbar {
  padding: 0.4rem 3rem !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);

  .headerLogoWrapper {
    border-width: 0px;
    left: 0px;
    top: 0px;
    width: 250px;
    height: 110px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .navItem {
    margin: 0 1.5rem;

    a {
      color: black !important;
    }

    a:hover {
      color: black !important;
    }
  }

  a {
    text-decoration: none;
  }

  .active {
    border-bottom: 3px solid black;
    @media (max-width: $breakpoint-tablet) {
      border-bottom: unset;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 1rem 1rem !important;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: red;
  }

  .loginBtn {
    background-color: #043d5d;
    width: 145px;
    border-radius: 0;
    height: 46px;
    @media (max-width: $breakpoint-tablet) {
      margin: 20px;
    }
  }
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: red !important;
}

.navItemsWrapper {
  @media (max-width: $breakpoint-tablet) {
    margin: 30px 0;
  }
}

.headerOuterWrapper {
  position: fixed;
  width: 100vw;
  z-index: 100;
}

#headerUserInfoOuterWrapper {
  &:after {
    display: none !important;
  }
  padding: 0rem 1rem;
  margin-left: -2rem;
  @media (max-width: $breakpoint-tablet) {
    margin: 2rem 0rem 1.5rem -1rem;
  }
  #headerUserInfoWrapper {
    width: 270px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    #userThumbnailWrapper {
      width: 46px;
      height: 46px;
      display: flex;
      border-radius: 2px;
      border: 1px solid rgba(204, 204, 204, 1);
      background-color: rgba(242, 242, 242, 1);
      align-items: center;
      justify-content: center;
      img {
        width: 1.5rem;
      }
    }
    #userInfoWrapper {
      p {
        color: black;
        margin: 0px;
        font-size: 14px;
        &:first-child {
          font-size: 16px !important;
        }
      }
    }
  }
}
