#curriculumPageWrapper .navigatorMenuWrapper .navigatorMenu .levelMenuWrapper .levelMenu p {
  font-size: 1.2rem;
}
#curriculumPageWrapper .navigatorMenuWrapper .navigatorMenu .levelMenuWrapper .levelMenuWrapper .levelMenu p {
  font-size: 1.1rem;
}
#curriculumPageWrapper
  .navigatorMenuWrapper
  .navigatorMenu
  .levelMenuWrapper
  .levelMenuWrapper
  .levelMenuWrapper
  .levelMenu
  p {
  font-size: 1rem;
}
