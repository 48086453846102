.passwordInputWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #122945;
  padding: 0.7rem 0.8rem 0.6rem 1.2rem;

  input {
    width: 90%;
    border: none;
    background-color: rgba(255, 255, 255, 0) !important;
  }

  div {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input {
    width: 90%;
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    border: none;
    background-color: rgba(255, 255, 255, 0) !important;
  }


  // -webkit-text-fill-color: rgb(0, 0, 0) !important;
}

.inputWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #122945;
  padding: 0.7rem 0.8rem 0.6rem 0.1rem;

  input {
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0) !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input {
    width: 90%;
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    border: none;
    background-color: rgba(255, 255, 255, 0) !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
