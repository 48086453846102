.textGroup {
  width: 35%;

  @media (max-width: 768px) {
    width: 100%;
  }

  margin: 2rem 0rem;

  p {
    margin-bottom: 0px;
  }
}

.inputGroup {
  // width: 35%;

  @media (max-width: 768px) {
    width: 100%;
  }

  margin: 2rem 0rem;

  p {
    margin-bottom: 0.5rem;
  }
}

.button {
  width: 14rem;
}

.radioGroup {
  div {
    margin-top: 0.7rem;
  }
}