.landingImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 500px;
  }

  @media (max-width: 500px) {
    margin-top: 70px;
  }
}

.jumbotron {
  padding: 100px 0px;
  color: #122945;
  .jumbotronTextWrapper {
    color: #122945;
    background-color: #ced9e6;
    box-shadow: 0 0 10px 10px #ced9e6;
    opacity: 0.95;
    border-radius: 20px;
    @media (max-width: 500px) {
      * {
        text-align: center;
      }
    }
  }
}

.trainingToolInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfb;
  border-bottom: 1px solid rgba(128, 128, 128, 0.342);
  border-top: 1px solid rgba(128, 128, 128, 0.342);

  h5 {
    padding: 30px;
    margin: 0;
    text-align: center;
  }
}

#advAndCharWrapper {
  padding-top: 80px;
  padding-bottom: 80px;

  #advAndCharHeader {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      width: 750px;
      text-align: center;
    }
  }

  .advantages {
    margin-top: 40px;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      padding-left: 30px;
    }

    * {
      margin: 5px;
    }

    .imagePart {
      flex: 2;
      background-color: rgba(242, 242, 242, 1);
      display: flex;
      justify-content: center;
      border: 1px solid rgba(228, 228, 228, 1);
      border-radius: 2px;
      align-items: center;
      width: 40px;
      height: 50px;
      img {
        height: 20px;
      }

      @media (max-width: 768px) {
        height: 300px;
      }
    }

    .textPart {
      flex: 6;
      * {
        margin-top: 15px;
      }

      h5:hover {
        color: #212529;
        cursor: pointer;
      }
    }
  }
}

.main {
  padding-top: 90px;
}

#aboutUsWrapper {
  padding-bottom: 80px;

  h1 {
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  #aboutUsTextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;

    p {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }

      text-align: center;
    }
  }

  .imagePart {
    div {
      background-color: rgba(242, 242, 242, 1);
      display: flex;
      justify-content: center;
      border: 1px solid rgba(228, 228, 228, 1);
      border-radius: 2px;
      align-items: center;
      height: 100%;

      @media (max-width: 768px) {
        height: 300px;
      }
    }
  }

  .textPart {
    margin-bottom: -20px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .aboutUsImageAtLeft {
    .imagePart {
      padding-left: 10px;

      @media (max-width: 768px) {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }

  .aboutUsImageAtRight {
    .imagePart {
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }

  .aboutUsFullText {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

#materialOuterWrapper {
  margin-top: 60px;
  min-height: 400px;
  background-color: #fbfbfb;
  border-bottom: 1px solid rgba(128, 128, 128, 0.342);
  border-top: 1px solid rgba(128, 128, 128, 0.342);
  padding-top: 80px;
  padding-bottom: 3rem;

  #materialInnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .materialCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: 60%;
      text-align: center;
      padding-top: 0.8rem;
      padding-bottom: 1.5rem;
    }

    .materialCardIconWrapper {
      margin-bottom: 1.5rem;

      img {
        width: 60px;
      }
    }

    .actionButton {
      background-color: #043d5d;
      border-radius: 0;
      padding: 10px 20px;
    }
  }

  #curriculumCardWrapper {
  }

  #dropBoxCardWrapper {
    border-left: 1px solid rgba(128, 128, 128, 0.205);
    .actionButton {
      border: 1px solid #043d5d !important;
      color: #043d5d !important;
      background-color: rgba(255, 255, 255, 0) !important;
      border-radius: 0;
      padding: 10px 20px;
    }

    @media (max-width: 768px) {
      border-left: unset;
      margin-top: 5rem;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 3rem;
  }
}
