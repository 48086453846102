#resetPasswordPageWarpper {
	min-height: 100vh;
	width: 100%;
  .imageWrapper {
    background-image: url("../../../../assets/images/files/PHAROS_Hero-Image_white_1200x630_light.svg");
		background-size: cover;
		background-position-x: center;
		background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    img {
      width: 20vw;
      background-color: #CED9E6;
      box-shadow: 0 0 10px 10px #CED9E6;
      opacity: .95;
      border-radius: 20px;
		}
		@media (max-width: 768px) {
			min-height: 50vh;
			img {
				width: 50vw;
			}
    }
  }
	.formWrapper {
		min-height: 100vh;
		padding: 5rem;
		@media (max-width: 768px) {
			padding: 2rem;
		}
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;
	}
}