#dropboxPageWrapper {
  min-height: 1000px;
  padding: 10rem 4rem 10rem 4rem;
  @media (max-width: 768px) {
    padding: 10rem 1rem 0 1rem;
  }

  #pageHeader {
    h6 {
      margin-top: 1.5rem;
    }
  }

  #uploadWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    @media (max-width: 768px) {
      margin-top: 1rem;
      justify-content: center;
    }

    .uploadButton,
    .contactButton {
      margin: 4px 0;
      padding-bottom: 0.7rem !important;
      font-size: 1.1rem !important;
      min-width: 205px;
    }

    .uploadButton {
      background-color: #5ac0be !important;
    }

    .contactButton {
      display: flex;

      button {
        width: 100%;
      }
    }

    .flexWrapper {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
