#dropboxTableWrapper {
  table {
    position: relative;
  }

  padding-top: 0px !important;

  margin-top: 2.5rem;
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;

  .tableHead {
    border: none;
    background-color: rgb(221, 221, 221);

    th {
      font-weight: 400;
      position: sticky;
      background-color: rgb(221, 221, 221);
      top: -1px;

      .tableHeadCellWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;

        .sortIconsWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.8rem;
          color: rgb(153, 153, 153);
          margin-left: 1rem;

          .topArrow {
            margin-bottom: -5px;
          }

          .bottomArrow {
            margin-top: -5px;


          }

          .selected {
            color: black;
          }
        }
      }

      .lastHead {
        justify-content: center;
      }
    }

    th {
      &:first-child {
        padding-left: 2rem;
      }

      &:last-child {
        text-align: center;
      }
    }
  }

  padding-right: 0px;
  border-right: 1px solid rgb(228, 228, 228);

  tbody {
    .selectedFile {
      cursor: pointer;
      background-color: rgb(228, 228, 228) !important;
    }

    tr {
      cursor: pointer;
      border: 1px solid rgb(228, 228, 228);

      .tableIcon {
        width: 2rem;
        margin: 0 1rem;

        @media (max-width: 767px) {
          display: block;
          text-align: center;
          margin: 1rem 4rem;
        }
      }

      td {
        @media (max-width: 767px) {
          text-align: center;

          &:first-child {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              margin: 0;
              margin-bottom: 20px;
            }
          }
        }


        &:last-child {
          text-align: center;
          padding-right: 25px;
        }
      }

      .tableCells {
        @media (max-width: 767px) {
          padding-top: 4rem;
        }
      }
    }

    tr:hover {
      cursor: pointer;
      background-color: white;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2) !important;
    }
  }
}