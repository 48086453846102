.phoneNumberWrapper {
  width: 100%;

  .codeButton {
    border-radius: 0px;
    border-right: none;
    border-color: #5AC0BE;
    height: 45px;
    padding: 0px 8px;
    padding-right: 8px;
  }

  .dropDownTrigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #5AC0BE;
    border-left: none;
    padding: 0px;
    padding-right: 8px;
  }

  .input {
    height: 45px;
  }

  .input:focus,
  .input[type]:focus,
  .uneditable-input:focus {
    border-color:#5AC0BE;
    box-shadow: 0 1px 1px #122945
    inset, 0 0 8px #122945
    ;
    outline: 0 none;
  }
  .flagsViewerWrapper {
    max-height: 150px;
    width: 220px;
    overflow-y: scroll;
    .flagViewerWrapper {
      width: 100%;
      display: flex;
      padding: 10px 15px; 
      justify-content: start;
      align-items: center;
      img {
        width: 28px;
      }
      & > div {
        padding-top: 2px;
        padding-left: 20px;
        font-size: 0.8rem;
      }
      cursor: pointer;
    }
    .flagViewerWrapper:hover {
      background-color: rgb(197, 233, 255)!important;
      color: #A0B9D0
      !important;
    }
    .selected, .selected:hover {
      background-color: #043D5D!important;
      color: white!important;
      cursor: not-allowed;
    }
  }
  .flagsViewerSearch {
    width: 100%;
    input {
      width: 100%;
      border: 1px solid rgb(255, 255, 255);
      margin-bottom: 7px;
      padding: 5px 10px;
      font-size: 0.8rem;
    }
    input:focus{
      outline: none;
  }
  }
}