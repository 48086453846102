.formErrorOuterWarpper {
  width: 100%;
  min-height: 60px;
	margin: 12px 0px;
  .formErrorInnerWarpper {
    width: 100%;
    height: 100%;
    padding: 0.8rem 1.8rem;
    display: flex;
    // justify-content: center;
    align-items: center;
    
    border-radius: 0.1rem;
	}
	
	.successAlert {
		background-color: #A0B9D0;
	}

	.errorAlert {
		background-color: #A0B9D0;
	}

  .alertIcon {
    width: 27px;
  }

  .alertText {
    padding-left: 1.5rem;
    font-size: 0.8rem;
  }
}