#resourcePageWrapper {
  .landingImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    @media (max-width: 500px) {
      margin-top: 70px;
    }
  }

  .jumbotron {
    padding: 150px 0px;
    color: #043d5d;
    @media (min-width: 576px) {
      padding: 9.6rem 2rem;
    }
    .jumbotronTextWrapper {
      color: #043d5d;
      background-color: #ced9e6;
      box-shadow: 0 0 10px 10px #ced9e6;
      opacity: 0.95;
      border-radius: 20px;
      @media (max-width: 500px) {
        * {
          text-align: center;
        }
      }
    }
  }
  min-height: 100vh;
  padding-top: 6rem;
  #resourcesWrapper {
    margin-top: 3rem;
    max-width: 992px;
  }
  h2 {
    margin-top: 5rem;
  }
  h3 {
    margin-bottom: 3rem;
    margin-top: 4rem;
    &:first-child {
      margin-top: 0rem;
    }
  }
  .linkWrapper {
    margin-left: 2rem;
    margin-top: 2rem;
    h6 {
      margin-bottom: 0.5rem;
    }
  }
  .listWrapper {
    margin-bottom: 5rem;
  }
}
