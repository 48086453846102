#footerOuterWrapper {
  border-top: 1px solid rgba(180, 180, 180, 0.205);
  border-bottom: 1px solid rgba(180, 180, 180, 0.205);
  background-color: white;
  #footerUpperPart {
    padding: 0.5rem 2rem 1rem 2rem;
    background-color: white;
    // border-top: 1px solid rgba(128, 128, 128, 0.205);

    & > div {
      padding-bottom: 2rem;
      // border-bottom: 1px solid rgba(128, 128, 128, 0.205);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .footerLogoWrapper {
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 250px;
      height: 110px;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    #backToTopWrapper {
      p {
        margin-bottom: 0;
      }

      cursor: pointer;

      @media (max-width: 768px) {
        margin-top: 3rem;
      }
    }
  }

  #footerLowerPartContainer {
    border-top: 1px solid rgba(128, 128, 128, 0.205);
  }

  #footerLowerPart {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    p,
    a {
      font-size: 13px;
      margin: 0;
    }

    #footerLowerPartLinks {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      p,
      a {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
}
