#contentViewer {
  padding: 2rem 0;
  text-align: justify;

  .contentMargin {
    margin-top: 3rem;
  }

  .video,
  img,
  table {
    width: 100%;
    margin-top: 15px;
  }

  figcaption {
    text-align: center;
    font-style: italic;
  }

  ul {
    li {
      margin-bottom: 1.2rem;
    }
  }
  table,
  td {
    border: 1px solid #212529;
  }

  td {
    padding: 4px;
  }

  thead,
  tfoot {
    background-color: #414042;
    color: #fff;
  }

  thead {
    text-align: center;
  }

  //styling quiz based on app inspection
  .react-quiz-container .questionWrapper .btn {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #a0b9d0;
    font-size: 15px;
    display: block;
    white-space: normal;
    text-align: unset;
  }

  .react-quiz-container .questionWrapper .btn.correct {
    background: #5ac0be;
    color: white;
  }

  .react-quiz-container .correct {
    background: #5ac0be;
  }

  .react-quiz-container .incorrect {
    background: #043d5d;
    color: white;
  }

  .react-quiz-container .selection-tag.multiple {
    background: #5ac0be;
  }

  .react-quiz-container .selection-tag.single {
    background: #043d5d;
  }

  .react-quiz-container .number-of-selection {
    background: #a0b9d0;
    margin-left: 5px;
  }

  .react-quiz-container .card-body h2:nth-of-type(2) {
    display: none;
  }
}
