#promptDialogWrapper {
	width: 100%;
	.bodyText {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 160px;
		margin-top: -20px;
	}
	.actionArea {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.cancelBtn {
			width: 40%;
			background-color: rgb(231, 43, 43)!important;
			margin-right: 30px;
		}
		.confirmBtn {
			width: 40%;
			margin-left: 30px;
			background-color: rgb(25, 202, 164)!important;
		}
	}
}