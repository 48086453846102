@font-face {
  font-family: "Century Gothic Std Regular";
  src: local("Century Gothic Std Regular"), url("../fonts/Century Gothic Std Regular.otf") format("opentype");
}

body * {
  font-family: "Century Gothic Std Regular";
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 920px;
  }
}

.jumbotron {
  margin-bottom: 0 !important;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(95, 95, 95) rgba(255, 166, 0, 0);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 166, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
  border-radius: 10px;
  border: 1px solid rgba(255, 166, 0, 0);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: black;
  background-color: #ffffff;
  border-color: #d6d6d6;
}

.btn-outline-secondary:hover {
  color: black;
  background-color: #ffffff;
  border-color: #d6d6d6;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 233, 233, 0) !important;
}

.errorText {
  color: red;
  font-size: 0.8rem;
}

.jumbotron {
  background-color: #ced9e6 !important;
  box-shadow: 0 0 10px #ced9e6;
  background-size: cover;
  background-image: url("../images/files/PHAROS_Hero-Image_white_1200x630_light.svg");
}

a {
  color: #043d5d;
  text-decoration: underline;
}

a:hover {
  color: #043d5d;
}

strong,
b {
  color: #043d5d;
}
