.flagListWrapper {
  height: 400px;
  overflow-y: auto;
}

.modalFooterwrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin: 0 10px;
    cursor: pointer;
  }

  margin: 10px 0;

  .confirmButton {
    background-color: #5AC0BE;
    padding: 10px 50px;
    border-radius: 0;
  }

  .confirmButton:hover {
    background-color: #5AC0BE;
  }
}

.languageBtnWarpper {
  width: 100%;
  height: 60px;
  border: 1px solid rgba(128, 128, 128, 0.274);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  padding: 9px 20px;
  justify-content: left;
  align-items: center;
  margin: 8px 0px;

  .flagIcon {
    width: 30px;
  }

  p {
    margin: 0px 0px 0px 15px;
  }

  transition: 0.2s;

  @media (max-width: 500px) {
    margin: 5px 0px;
  }
}

.active {
  background-color: #5AC0BE;

  p {
    color: white;
  }

  cursor: not-allowed;
}

.languageBtnWarpper:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.disabledButton {
  background-color: rgba(128, 128, 128, 0.335)!important;
  border: 1px solid rgba(128, 128, 128, 0.102)!important;
}