.textGroup {
  width: 35%;

  @media (max-width: 768px) {
    width: 100%;
  }

  margin: 2rem 0rem;

  p {
    margin-bottom: 0px;
  }
}

.inputGroup {
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }

  margin: 2rem 0rem;

  p {
    margin-bottom: 0.5rem;
  }
}

.button {
  width: 14rem;
}

.radioGroup {
  div {
    margin-top: 0.7rem;
  }
}

.tagsWrapper {
  background-color: #043D5D;
  width: 40vw;
  margin: 3rem 0 4rem 0;
  @media (max-width: 768px) {
    width: 80vw;
  }
}