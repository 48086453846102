#dropboxModalWrapper {
  .inputWrapper {
    margin: 0.6rem 0;

    .inputInnerWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(128, 128, 128, 0.253);
      padding: 0.7rem 0.8rem 0.6rem 0.1rem;

      input {
        width: 100%;
        border: none;
        background-color: rgba(255, 255, 255, 0) !important;
      }

      textarea {
        width: 90%;
        border: none;
        outline: none;
        height: 100px;
        overflow-y: scroll;
        resize: none;
        background-color: rgba(255, 255, 255, 0) !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus,
      input {
        width: 90%;
        -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
        -webkit-transition-delay: 9999s;
        border: none;
        background-color: rgba(255, 255, 255, 0) !important;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number],
      textarea {
        -moz-appearance: textfield;
      }
    }
  }

  .uploadBtnWrapper {
    margin: 1rem 0 0.8rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .uploadBtn {
      width: 93%;
    }
  }
  .errorText {
    margin-top: 15px;
    margin-bottom: -10px;
  }
}