
	#dropBoxFileDetailsModalWrapper {
		margin-top: 2.5rem;
		min-height: 400px;
		border: 1px solid rgb(219, 219, 219);
		padding: 1.6rem 1.5rem;
		#detailsHeader {
			padding-bottom: 10px;
			border-bottom: 1px solid rgb(214, 214, 214);
		}
		.fileActions {
			width: 100%;
			display: flex;
			margin-top: -30px;
			justify-content: flex-end;
			align-items: center;
			.editBtn, .deleteBtn {
				width: 35px;
				height: 35px;
				font-size: 0.8rem;
				margin-left: 1rem;
				border-radius: 50px;
				padding-top: 3px;
				border: none;
				outline: none!important;
			}
			.editBtn {
				background-color: #122945;
				color: white;
			}
			.deleteBtn {
				background-color: rgb(255, 92, 92);
				color: white;
			}
		}
		#downloadBtnWrapper {
			padding-top: 20px;
			border-top: 1px solid rgb(214, 214, 214);
			display: flex;
			justify-content: center;
			align-items: center;
			a {
				width: 100%;
				.downloadButton {
					background-color: #5AC0BE!important;
					padding-bottom: 0.7rem!important;
					font-size: 1.1rem!important;
					width: 95%;
				}
			}
		}
		.fluidRow {
			margin: 1.5rem 0;
			display: flex;
			justify-content: center;
			.titlePart {
				color: grey;
				flex: 1;
			}
			.dataPart {
				flex: 2
			}
		}
		#fileTopics {
			.topicChip {
				white-space: pre-line;
				display: inline-block;
				font-size: 0.8rem;
				background-color: black;
				color: rgba(255, 255, 255, 0.959);
				margin: 0.3rem 0.2rem;
				cursor: pointer;
				font-weight: 600;
				padding: 0.5rem 0.8rem 0.3rem 0.8rem;
		
			}
		}
	}