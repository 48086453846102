#curriculumPageWrapper {
  min-height: 1000px;
  position: relative;
  padding-top: 9rem;

  .hideOnMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .navigatorMenuWrapper {
    width: 30vw;
    min-height: 70vh;
    max-height: 600px;
    padding: 1.5rem 1.2rem 2rem 1.2rem;
    margin-bottom: 5rem;
    position: fixed;

    .navigatorMenu {
      background-color: #fbfbfb;
      border: 1px solid #d1d1d1;
      min-height: 70vh;
      max-height: 600px;
      width: 100%;
      padding: 2.5rem 1.5rem 15.5rem 0;

      overflow-y: scroll;

      .levelMenuWrapper {
        padding-left: 2rem;

        .levelMenu {
          font-size: 1.1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          color: rgb(141, 140, 140);

          p {
            margin: 0;
            font-size: 0.92rem;
          }

          i {
            font-size: 0.8rem;
          }

          margin-bottom: 1rem;
        }

        .levelMenu:hover {
          color: #5ac0be;
        }

        .selectedMenu {
          color: rgb(42, 42, 42) !important;
          font-weight: 600;
        }

        .noSign {
          & > div {
            display: none;
          }
        }
      }
    }
  }

  .contentDisplayWrapper {
    // min-height: 1000px;
    max-width: 992px;
    padding: 1.5rem 1.2rem 2rem 1.2rem;
  }

  #bottomNavWrapper {
    max-width: 992px;
    height: 100px;
    // background-color: red;
    padding: 1.5rem 1.2rem 2rem 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 300px;
    .nextButton {
      background-color: #5ac0be;
      border: 1px solid #5ac0be;
      color: white;
      padding: 1rem 2.5rem 0.8rem 2.5rem;

      &:focus,
      &:active {
        outline-color: #043d5d;
      }
    }
    .prevButton {
      background-color: white;
      border: 1px solid #5ac0be;
      color: #5ac0be;
      padding: 1rem 2.5rem 0.8rem 2.5rem;
    }
  }
}
