#forgotPasswordPageWarpper {
  min-height: 100vh;
  width: 100%;
  .imageWrapper {
    background-image: url("../../../../assets/images/files/PHAROS_Hero-Image_white_1200x630_light.svg");
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    img {
      width: 20vw;
      background-color: #ced9e6;
      box-shadow: 0 0 10px 10px #ced9e6;
      opacity: 0.95;
      border-radius: 20px;
    }
    @media (max-width: 768px) {
      min-height: 50vh;
      img {
        width: 50vw;
      }
    }
  }
  .formWrapper {
    min-height: 100vh;
    padding: 5rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
    display: flex;
    justify-content: left;
    align-items: center;
    .formContainer {
      width: 600px;
      padding-left: 5rem;
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 0;
      }
    }
    #forgotPasswordWrapper {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        text-align: left;
      }

      p {
        margin: 1rem 0;
        text-align: left;
        // padding: 0 1.5rem;
      }

      form {
        margin-top: 1rem;
        width: 90%;

        @media (max-width: 500px) {
          width: 100%;
        }

        .formInput {
          background-color: transparent !important;
          color: rgb(29, 29, 29) !important;
          padding: 1.5rem 0.8rem 1.4rem 0.8rem;
          border: 1px solid rgba(128, 128, 128, 0.253);
          border-radius: 1px;
          margin: 0.7rem 0rem;
        }

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgba(128, 128, 128, 0.459);
          opacity: 1;
          /* Firefox */
        }
      }

      .forgotPasswordBtn {
        margin: 1rem 0;
        border: none;
        border-radius: 1px;
        width: 90%;

        @media (max-width: 500px) {
          width: 100%;
        }

        background-color: #043d5d;
        padding: 0.9rem 0 0.7rem 0;
      }

      #signUpAccess {
        border-top: 1px dashed rgba(128, 128, 128, 0.411);
        width: 90%;

        @media (max-width: 500px) {
          width: 100%;
        }

        text-align: center;
        margin-bottom: 1rem;

        p {
          cursor: pointer;
        }
      }

      #forgotPasswordHelperPanel {
        display: flex;
        align-items: center;
        width: 90%;

        @media (max-width: 500px) {
          width: 100%;
        }

        justify-content: space-between;

        div {
          cursor: pointer;
        }

        .checkbox {
          margin-top: 4px;
          // Aligns the checkmark and label centrally
          align-items: center;
          cursor: pointer;
          display: flex;
          position: relative;

          // Darker grey checkmark when hovering
          &:hover {
            .checkbox__input {
              ~ .checkbox__checkmark {
                background-color: #aaa;
              }
            }
          }

          // Hidden input
          &__input {
            cursor: pointer;
            height: 0;
            opacity: 0;
            position: absolute;
            width: 0;

            &:checked,
            &:checked:hover {
              ~ .checkbox__checkmark {
                background-color: #043d5d;

                &::after {
                  display: block;
                }
              }
            }

            // This is key, when the user tabs to the input, you need a visual representation they are focusing on the checkmark (Try tabbing to the checkmark)
            &:focus {
              ~ .checkbox__checkmark {
                border-color: #043d5d;
              }
            }
          }

          // The custom checkmark
          &__checkmark {
            align-items: center;
            background-color: #ffffff;
            border: 1px solid black;
            display: flex;
            justify-content: center;
            margin-right: 0.5rem;
            min-height: 1.1rem;
            min-width: 1.1rem;

            // Pure CSS checkmark tick
            &::after {
              border: solid #fff;
              border-width: 0 3px 3px 0;
              content: "";
              display: none;
              height: 10px;
              transform: rotate(45deg);
              width: 5px;
            }
          }
        }
      }
    }
  }
}
