.main {
  padding-top: 3rem;
  .userSettingWrapper {
    padding-bottom: 4rem;
    padding-top: 6rem;
  }

  .userSettingsPageWrapper {
    padding: 6rem 3rem 4rem 3rem;

    @media (max-width: 1023px) {
      padding: 3rem 1rem 4rem 1rem;
    }

    display: flex;
    flex-direction: row;

    position: relative;
    @media (max-width: 1023px) {
      position: unset;
    }

    #settingsViewer {
      padding-left: 31rem;
      min-height: 800px;
      @media (max-width: 1023px) {
        padding-left: 0rem;
      }
    }
  }

}