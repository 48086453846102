#mobileNavBarWrapper {
  @media (min-width: 1024px) {
    display: none;
  }
  padding-top: 7rem;
  #mobileNavBar {
    width: 100%;
    height: 100px;
    margin-bottom: -6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .option {
      width: 20%;
      text-align: center;
      font-size: 1rem;
      background-color: rgb(219, 219, 219);
      color: rgb(110, 110, 110);
      padding: 0.7rem 0 0.3rem 0;
    }
    .selectedOption {
      width: 20%;
      text-align: center;
      font-size: 1.3rem;
      background-color: #122945;
      color: white;
      font-size: 1.6rem;
      padding-top: 0.5rem;
    }
  }
}
