#sideNavbar {
	width: 420px;
	background-color: #FBFBFB;
	height: 800px;
	margin-top: 3rem;
	border: 1px solid rgb(218, 218, 218);
	padding: 1.5rem;
	position: fixed;
	@media (max-width: 1023px) {
		display: none;
	}
	.selectedOption {
		display: flex;
		flex-direction: row;
		background-color: white;
		box-shadow: 0px 0px 8px rgba(0,0,0,0.2);
		height: 60px;
		padding: 1.2rem 1.5rem;
		font-size: 1.2rem;
		color: #A0B9D0;
		cursor: unset;
		margin: 0.5rem 0;
	}
	.option {
		margin: 0.5rem 0;
		display: flex;
		flex-direction: row;
		height: 60px;
		padding: 1.2rem 1.5rem;
		font-size: 1.1rem;
		color: rgb(87, 87, 87);
		cursor: pointer;
	}
}